import React from "react";
import { useTrail, animated } from "react-spring";
import { OutboundLink } from "gatsby-plugin-gtag-outbound";

import Layout from "../components/layout";

const projects = [
    {
        code: 'perform-id',
        name: 'PerformID',
        url: 'https://performid.com',
        title: 'CTO',
        body: 'PerformID specialises in building loyalty solutions for the world\'s largest banks. I worked closely with Alex (Founder of PerformID) on the original prototype in 2020.',
    },
    {
        code: 'tiny',
        name: 'Tiny',
        url: 'https://tiny.cloud',
        title: 'Senior JavaScript Engineer (TinyMCE)',
        body: 'TinyMCE is the world’s most popular open source web-based WYSIWYG editor. Trusted and loved by millions of developers, and integrated into thousands of applications.',
    },
    {
        code: 'fundamental',
        name: 'Fundamental',
        url: 'https://github.com/fundamentalshop',
        title: 'Co-Founder',
        body: 'A couple of mates who build things together. Chris Sealey and I have worked together all over the place, Fundamental is our workshop for software, graphic design, and branding.',
    },
    {
        code: 'allt',
        name: 'allt',
        url: 'https://allt.tv/',
        title: 'Tech Lead',
        body: 'Don\'t search. Find. allt enables you to interact with what you are viewing live in a whole new way.',
    },
    {
        code: 'identitii',
        name: 'Identitii',
        url: 'https://identitii.com/',
        title: 'Integration Specialist',
        body: 'Identitii is using blockchain and tokenisation to create, deploy and activate a new, connected ecosystem for payments.',
    },
    {
        code: 'sportility',
        name: 'Sportility',
        url: 'https://sportility.co/',
        title: 'Lead Front-End Developer',
        body: 'Sponsored gear for every team. Create and customise bespoke sportswear, then subsidise the unit cost by adding sponsors.',
    },
    {
        code: 'better-consult',
        name: 'BetterConsult',
        url: 'https://au.betterconsult.com/',
        title: 'Lead Developer/Product Design',
        body: "More time, more control and less admin for doctors. A pre-consultation tool that captures a patient's clinical information and then translates the data for the doctor, straight into their medical software.",
    },
    {
        code: 'connect-me',
        name: 'ConnectMe',
        url: 'https://au.connect-me.com/home/',
        title: 'Full-Stack Developer',
        body: 'A secure practitioner to client communication platform. Confidential diary, instant messaging, and secure video chat using WebRTC.',
    },
    {
        code: 'healthshare',
        name: 'HealthShare.com.au',
        url: 'https://www.healthshare.com.au/',
        title: 'Full-Stack Developer',
        body: 'Powering informed health decisions at all stages of the healthcare journey.',
    },
    {
        code: 'ww-api',
        name: 'WillyWeather API v2',
        url: 'https://www.willyweather.com.au/info/api.html',
        title: 'Full-Stack Developer',
        body: 'A feature-rich weather API delivering accurate local weather data for over 17,000 locations, from best-in-class weather providers, including the Australian Bureau of Meteorology.',
    },
    {
        code: 'ww-revamp',
        name: 'WillyWeather Revamp',
        url: 'https://www.willyweather.com.au/',
        title: 'Full-Stack Developer/Product Design',
        body: "Australia's favourite source for everything Weather.",
    },
    {
        code: 'ww-ios',
        name: 'WillyWeather iOS App',
        url: 'https://www.willyweather.com.au/info/ios.html',
        title: 'Product Design/QA',
        body: 'The WillyWeather iPhone app is the best weather app you can get on the App Store, free or paid. An average of 4.7/5 stars with over 120,000 reviews.',
    },
];

const AnimatedOutboundLink = animated(OutboundLink);

const IndexPage = () => {
    const config = { mass: 1.5, tension: 750, friction: 45 }
    const style = { from: { transform: 'translate3d(0, 50px, 0)', opacity: 0 }, to: { transform: 'translate3d(0, 0, 0)', opacity: 1 }, config };
    const trail = useTrail(projects.length, style);
    return (
        <Layout>
            <div className="projects">
                {trail.map((props, i) => {
                    const project = projects[i];
                    return (
                        <AnimatedOutboundLink style={props} className={`project ${project.code}`} href={project.url} key={i}>
                            <h1>{project.name}</h1>
                            <h2>{project.title}</h2>
                            <p>{project.body}</p>
                        </AnimatedOutboundLink>
                    );
                })}
            </div>
        </Layout>
    );
}

export default IndexPage
